exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lithome-search-js": () => import("./../../../src/pages/lithome/search.js" /* webpackChunkName: "component---src-pages-lithome-search-js" */),
  "component---src-templates-all-products-js": () => import("./../../../src/templates/all-products.js" /* webpackChunkName: "component---src-templates-all-products-js" */),
  "component---src-templates-categorized-products-js": () => import("./../../../src/templates/categorized-products.js" /* webpackChunkName: "component---src-templates-categorized-products-js" */)
}

