import { BASKET_CONSTANTS } from '../shared/constants/basket-constants';
import {
  showLimitReachedWarning,
  showProductAdded,
  showProductRemoved,
} from './../services/toast';

export const basketReducer = (state, action) => {
  switch (action.type) {
    case BASKET_CONSTANTS.ADD_ITEM:
      const productToAdd = action.payload;
      const basketItem = state.filter(
        (item) => item.code === productToAdd.code
      )[0];

      if (!basketItem) {
        showProductAdded(productToAdd.name);
        return [
          ...state,
          {
            ...action.payload,
            quantity: 1,
            updatedAt: new Date(),
          },
        ];
      }

      if (
        basketItem.maximumAmount !== 0 &&
        basketItem.quantity >= basketItem.maximumAmount
      ) {
        showLimitReachedWarning(productToAdd.name, productToAdd.maximumAmount);
      } else {
        showProductAdded(productToAdd.name);
        basketItem.quantity++;
        basketItem.updatedAt = new Date();
      }

      return [...state];

    case BASKET_CONSTANTS.REMOVE_ITEM:
      showProductRemoved(action.payload.name);
      return state.filter((item) => item.code !== action.payload.code);

    case BASKET_CONSTANTS.UPDATE_ITEM_QUANTITY:
      state.find((item) => item.code == action.payload.code).quantity =
        action.payload.newQuantity;

      return [...state];

    case BASKET_CONSTANTS.UPDATE_BASKET:
      const updatedBasket = [];

      for (let product of action.payload) {
        if (product.quantity === 0) continue;

        if (
          product.maximumAmount !== 0 &&
          product.quantity > product.maximumAmount
        ) {
          showLimitReachedWarning(product.name, product.maximumAmount);
          product.quantity = product.maximumAmount;
        }

        updatedBasket.push(product);
      }

      return updatedBasket;

    case BASKET_CONSTANTS.INCREMENT_ITEM_QTY:
      state.find((item) => item.code === action.payload.code).quantity++;

      return [...state];

    case BASKET_CONSTANTS.DECREMENT_ITEM_QTY:
      state.find((item) => item.code === action.payload.code).quantity--;

      return [...state];

    case BASKET_CONSTANTS.CLEAR_BASKET:
      return [];

    default:
      return state;
  }
};
