import { toast } from 'react-toastify';

const toastConfig = {
  hideProgressBar: true,
  position: 'top-center',
};

const showInfo = (body) => {
  toast.info(body, toastConfig);
};

const showWarning = (body) => {
  toast.warning(body, toastConfig);
};

const showError = (body) => {
  toast.error(body, toastConfig);
};

const showLimitReachedWarning = (productName, limit) => {
  showWarning(
    `Product "${productName}" can only be added ${limit} times. You are already at your limit. Please contact us to buy more.`
  );
};

const showProductAdded = (productName) => {
  showInfo(`${productName} has been added to your shopping basket.`);
};

const showProductRemoved = (productName) => {
  showInfo(`${productName} has been removed from your shopping basket.`);
};

export {
  showInfo,
  showWarning,
  showError,
  showLimitReachedWarning,
  showProductAdded,
  showProductRemoved,
};
