import React, { createContext, useEffect, useReducer } from 'react';
import * as ls from 'local-storage';
import { ToastContainer } from 'react-toastify';

import { basketReducer } from '../reducers/basketReducer';

const basketContextDefaults = {
  basket: [],
};

const BasketContext = createContext(basketContextDefaults);

export const BasketProvider = (props) => {
  const [basket, dispatch] = useReducer(basketReducer, [], () => {
    const localStorageData = ls.get('basket');

    return localStorageData ? ls.get('basket') : [];
  });

  useEffect(() => {
    ls.set('basket', basket);
  }, [basket]);

  return (
    <BasketContext.Provider
      value={{
        basket,
        dispatch,
      }}
    >
      <ToastContainer />
      {props.children}
    </BasketContext.Provider>
  );
};

export default BasketContext;
